import * as React from "react";
import { CircularProgress } from "@mui/material";

const PaymentForm = (props: any) => {
    const [paymentUrl, setPaymentUrl] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [name,setName] = React.useState("");
    React.useEffect(() => {
        if (props !== null || props !== undefined) {
            setPaymentUrl(props.paymentUrl);
            setName(props.Name)
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        }
    }, [props])
    return (
        <div className={`${(name ==="Home") ? "jotForm displaySpinCenter displaySpinCenterMarginTop" : "jotForm displaySpinDonateCenter paddingLeft10 paddingRight10"}`}>
            {
                loading ? (
                    <CircularProgress className="circularSpin spinLeft" />
                ) : (
                    <iframe
                        src={paymentUrl} 
                        name="donorbox"
                        title="donorbox"
                        seamless={true}
                        allow="payment;"
                        allowFullScreen={true}
                        frameBorder="0" scrolling="no"
                        style={{ minWidth: '100%', height: "100%", border: 'none' }}
                        className="paymentIframe" >
                    </iframe>
                )
            }
        </div>
    )
}
export default PaymentForm;