import * as React from "react";
import eventsIcon from "../../Images/events.jpeg";
import FooterComponent from '../footer/footer';
import EventAndNewsComponent from "./events-news";

const EventsComponent = (props: any) => {
    const myRef = React.useRef<HTMLDivElement>(null);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 576 && window.innerWidth < 1024);
    const [isBigScreen, setIsBigScreen] = React.useState(window.innerWidth > 1330);
    const [isLessMobile, setIsLessMobile] = React.useState(window.innerWidth < 576);

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const istablet = window.innerWidth > 576 && window.innerWidth < 1024;
            const islessmobile = window.innerWidth < 576;
            const isbigScreen = window.innerWidth > 1330;
            if (istablet !== isTablet) {
                setIsTablet(istablet);
            } else if (isLessMobile !== islessmobile) {
                setIsLessMobile(islessmobile)
            } else {
                setIsBigScreen(isbigScreen)
            }
        }, false);

        if (myRef && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: "center"
            });
        }
    }, [isTablet, isLessMobile, isBigScreen])

    return (
        <div className="main-body">
            <EventAndNewsComponent />
            <div className='aboutUs marginBottom10'>
                {
                    isTablet || isLessMobile ? (
                        isLessMobile ? (
                            <div className="row">
                                <div className="col-sm-12">
                                    <img className='left-img ImageNewIcon img-responsive padding10' src={eventsIcon} alt="eventsIcon" />
                                </div>
                                <div className="col-sm-12 marginTop5Percent">
                                    <div className="WhatWeDo">
                                        <div className="side p40 marginTop20 flex justify-center flex">
                                            <span className='p40 backgroundColorDefault textCenter fontFamilyTimesNewRoman fontSize34'>
                                                Our inaugural event is just around the corner! <br /> Check back here for the date, time, and location.<br /><br />
                                            </span>
                                        </div>
                                        <div className="side p40 flex justify-center flex">
                                            <span className="p40 backgroundColorDefault textCenter fontFamilyTimesNewRoman fontSize45">We hope to see you there!</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ):(<div className="row">
                        <div className="col-sm-6">
                            <img className='left-img img-responsive ImageNewIcon padding10' src={eventsIcon} alt="eventsIcon" />
                        </div>
                        <div className="col-sm-6 marginTop5Percent">
                            <div className="WhatWeDo">
                                <div className="side p40 marginTop20 flex justify-center flex">
                                    <span className='p40 backgroundColorDefault textCenter fontFamilyTimesNewRoman fontSize34'>
                                        Our inaugural event is just around the corner! <br /> Check back here for the date, time, and location.<br /><br />
                                    </span>
                                </div>
                                <div className="side p40 flex justify-center flex">
                                    <span className="p40 backgroundColorDefault textCenter fontFamilyTimesNewRoman fontSize45">We hope to see you there!</span>
                                </div>
                            </div>
                        </div>
                    </div>)
                    ) : (
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <img className='left-img img-responsive padding10' src={eventsIcon} alt="eventsIcon" />
                                    </div>
                                    <div className="col-sm-6 marginTop5Percent">
                                        <div className="WhatWeDo">
                                            <div className="side p40 marginTop20 flex justify-center flex">
                                                <span className='p40 backgroundColorDefault textCenter fontFamilyTimesNewRoman fontSize34'>
                                                    Our inaugural event is just around the corner! <br /> Check back here for the date, time, and location.<br /><br />
                                                </span>
                                            </div>
                                            <div className="side p40 marginTop20 flex justify-center flex">
                                                <span className="p40 backgroundColorDefault textCenter fontFamilyTimesNewRoman fontSize45">We hope to see you there!</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <FooterComponent />
        </div>
    )
}
export default EventsComponent;