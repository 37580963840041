import React, { Fragment } from 'react';
import HomeComponent from './components/home/home-component';
import ContactUsComponent from './components/contact-us/contact-us';
import DonationComponent from './components/donation/donation';
import GetInvolvedComponent from './components/get-involved/get-involved';
import WhatWeDoComponent from './components/what-we-do/what-we-do';
import AboutUsComponent from './components/who-we-are/about-us';
import OurBoardComponent from './components/who-we-are/our-board';
import OurStaffComponent from './components/who-we-are/our-staff';
import FounderStoryComponent from './components/who-we-are/founder-story';
import AccommodationsRequirementComponent from './components/accommodations/accommodation-requirement';
import FindAccommodationsComponent from './components/accommodations/find-accommodations';
import EventsComponent from './components/events-and-news/events';
import NewsComponent from './components/events-and-news/news';
import OnlineApplicationComponent from './components/accommodations/online-application';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import ScrollToTopComponent from "./components/scrollToTop";
import PageNotFound from "./components/page-not-found/page-not-found"
import SponsorAFamily from './components/sponsor/sponsor-a-family';

const RouteComponent = () => {
    return (
        <Router>
            <ScrollToTopComponent />
            <Fragment>
                <Routes>
                    <Route path='/' element={<HomeComponent />} />
                    <Route path='/contact-us' element={<ContactUsComponent />} />
                    <Route path='/donation' element={<DonationComponent />} />
                    <Route path='/get-involved' element={<GetInvolvedComponent />} />
                    <Route path='/what-we-do' element={<WhatWeDoComponent />} />
                    <Route path='/who-we-are' element={<AboutUsComponent />} />
                    <Route path='/our-board' element={<OurBoardComponent />} />
                    <Route path='/our-staff' element={<OurStaffComponent />} />
                    <Route path="/accommodations" element={<AccommodationsRequirementComponent />} />
                    <Route path="/find-accommodations" element={<FindAccommodationsComponent />} />
                    <Route path="/online-application" element={<OnlineApplicationComponent />} />
                    <Route path="/news" element={< NewsComponent />} />
                    <Route path="/events" element={<EventsComponent />} />
                    <Route path="/founder-story" element={<FounderStoryComponent />} />
                    <Route path="/sponsor-a-family" element={<SponsorAFamily />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Fragment>
        </Router>
    )
}

export default RouteComponent;