import * as React from "react";
import AboutUs2 from "../../Images/about-us-2.jpeg";
import FooterComponent from "../footer/footer";
import WhoWeAreComponent from "./who_we_are";
import "../../stylesheets/about-us1.css";

const AboutUsComponent = (props: any) => {
    const myRef = React.useRef<HTMLDivElement | null>(null);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 576 && window.innerWidth < 992);
    const [isTabletMore, setIsTabletMore] = React.useState(window.innerWidth > 992 && window.innerWidth < 1280);
    const [isLessMobile, setIsLessMobile] = React.useState(window.innerWidth < 576);
    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const istablet = window.innerWidth > 576 && window.innerWidth < 992;
            const istabletMore = window.innerWidth > 992 && window.innerWidth < 1280;
            const islessmobile = window.innerWidth < 576;
            if (istablet !== isTablet) {
                setIsTablet(istablet);
            }
            else if (istabletMore !== isTabletMore) {
                setIsTabletMore(istabletMore);
            } else {
                setIsLessMobile(islessmobile)
            }
        }, false);

        if (myRef && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: "center"
            });
        }
        if (window.location.pathname === "/who-we-are/founder-story") {
            if (myRef && myRef.current) {
                myRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: "center"
                });
            }
        }
    }, [isTablet, isLessMobile, isTabletMore])
    return (
        <div className="main-body">
            <WhoWeAreComponent />
            <section className='section'>
                {
                    isLessMobile || isTablet ? (
                        isLessMobile ?(
                            <div className="marginTop20">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="side">
                                        <img className="rounded" alt="About Us2" src={AboutUs2} />
                                    </div>
                                </div>
                                <div className="side p40 marginBottom40 paddingTop15 flex flex-col col-sm-6">
                                    <p className="p40 padding10 textCenter fontSize16 fontFamilyTimesNewRoman backgroundColorDefault">
                                        Aashray is a Hindi word which, as a noun, can mean refuge, shelter, harbor, or haven. As a verb, it can mean to take refuge, to harbor, to give shelter, or to be under somebody's shelter and protection. We believe our name encapsulates our mission of caring for and uniting families. At Aashray, a 501(c)(3) non-profit corporation, we understand that fighting a childhood illness can be expensive for a family, particularly those away from the familiarities of home. To help ease some of the financial and emotional costs, we use local hotels to provide no-cost accommodations. We hope that by keeping families together we may be able to alleviate some of their heartache, in turn improving the well-being of their children.
                                    </p>
                                </div>
                            </div>
                        </div>
                        ) : (
                                <div className="containerAboutUs">
                                    <img src={AboutUs2} alt="AboutUs2" className="image" />
                                    <p className="p40 padding10 aboutUsFontSize fontSize16 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault">
                                        Aashray is a Hindi word which, as a noun, can mean refuge, shelter, harbor, or haven. As a verb, it can mean to take refuge, to harbor, to give shelter, or to be under somebody's shelter and protection. We believe our name encapsulates our mission of caring for and uniting families. At Aashray, a 501(c)(3) non-profit corporation, we understand that fighting a childhood illness can be expensive for a family, particularly those away from the familiarities of home. To help ease some of the financial and emotional costs, we use local hotels to provide no-cost accommodations. We hope that by keeping families together we may be able to alleviate some of their heartache, in turn improving the well-being of their children.
                                    </p>
                                </div>
                        )
                    ) : (

                        isTabletMore ?(
                            <div className="containerAboutUs">
                                    <img src={AboutUs2} alt="AboutUs2" className="image" />
                                    <p className="p40 padding10 aboutUsFontSize fontSize23 textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault">
                                        Aashray is a Hindi word which, as a noun, can mean refuge, shelter, harbor, or haven. As a verb, it can mean to take refuge, to harbor, to give shelter, or to be under somebody's shelter and protection. We believe our name encapsulates our mission of caring for and uniting families. At Aashray, a 501(c)(3) non-profit corporation, we understand that fighting a childhood illness can be expensive for a family, particularly those away from the familiarities of home. To help ease some of the financial and emotional costs, we use local hotels to provide no-cost accommodations. We hope that by keeping families together we may be able to alleviate some of their heartache, in turn improving the well-being of their children.
                                    </p>
                                </div>
                        ):(
                            <div className="row samarthPhotoRow">
                            <div className="col-sm-6">
                                <div className="side mr-2">
                                    <img className="rounded" alt="About Us2" src={AboutUs2} />
                                </div>
                            </div>
                            <div className="side p40 marginBottom40 flex textAlignLeft flex-col col-sm-6">
                                <p className="p40 fontSize23 textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault">
                                    Aashray is a Hindi word which, as a noun, can mean refuge, shelter, harbor, or haven. As a verb, it can mean to take refuge, to harbor, to give shelter, or to be under somebody's shelter and protection. We believe our name encapsulates our mission of caring for and uniting families. At Aashray, a 501(c)(3) non-profit corporation, we understand that fighting a childhood illness can be expensive for a family, particularly those away from the familiarities of home. To help ease some of the financial and emotional costs, we use local hotels to provide no-cost accommodations. We hope that by keeping families together we may be able to alleviate some of their heartache, in turn improving the well-being of their children.
                                </p>
                            </div>
                        </div>
                        )
                    )
                }
            </section >
            <div className="space"></div>
            <FooterComponent />
        </div >
    )
}
export default AboutUsComponent;