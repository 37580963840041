import * as React from "react";
import FooterComponent from "../footer/footer";
import WhoWeAreComponent from "./who_we_are";
import Founders_True_Story from "../../Images/Founders_True_Story.jpg";

const FounderStoryComponent = (props: any) => {
    return (
        <div className="main-body">
            <WhoWeAreComponent />
            <section className='section'>
                <div className="textAlignCenter marginTop30">
                    <span className='founderStorySpan fontWeightBold'>Our Founder's Story</span>
                </div>

                <div className="marginTop10">
                    <p className="padding10  p30 fontSize17 max-w-sm mx-auto backgroundColorDefault">
                        Constricted to a small cot adjacent to the only window in the room, my mother tossed, turned, fluffed her deflated pillow and tried to stretch out her legs. Despite her 5’2” frame, the cot felt both too short and too narrow. But it didn’t matter much anyway. Ever since I had been diagnosed with IGA Nephropathy my mother was used to restless nights and had practically learned to sleep with one eye open. Today, though, was an auspicious evening. I had just received a kidney transplant. Used to the constant beeping of machines since my weeks spent in the ICU, my interspersed groaning still provided a terrible soundtrack for sleep. Nonetheless, her only concern was still my well-being. Often waking to console my whimpering, she patiently waited for her only respite, the sight of my father’s arrival in the morning.
                    </p>
                </div>

                <div className="founderDivImage">
                    <img className="responsive ImageOfFounder" alt="Founder Story" src={Founders_True_Story} />
                </div>

                <div className="marginTop25">
                    <p className="padding10  p30 fontSize17 max-w-sm mx-auto backgroundColorDefault">
                        My father arrived after the traffic-ridden hour-long drive to Philadelphia. Having just sent my sister off to school, the usual traffic from Academy Rd. to Girard Ave. luckily wasn’t too exhausting today. My mother welcomed his arrival with a sigh and a smile. But before she could sit back and relax, the transplant team made their rounds. Fortunately, they brought great news. Content, my parents began eating the breakfast my father had brought with him. But, given it was only the day after my transplant, their focus quickly shifted back to my well-being. Unfortunately, my father could only provide my mother with a few hours of rest before he had to leave to beat the afternoon rush. Of course, he promised to bring back dinner.
                    </p>
                </div>

                <div className="margin10Top">
                    <p className="padding10  p30 fontSize17 max-w-sm mx-auto backgroundColorDefault">
                        My name is Samarth Mehta. I was diagnosed an autoimmune kidney disease at the age of 8. I underwent rounds of chemotherapy at Bristol Myers Squibb Children’s Hospital and was treated there during dialysis. Yet amongst all that chaos, the only constant was the care my parents unselfishly provided. They have given me more than I can hope to repay.
                    </p>
                </div>

                <div className="margin10Top">
                    <p className="padding10  p30 fontSize17 max-w-sm mx-auto backgroundColorDefault">
                        But my parents are not alone. Every parent of a child with medical needs sacrifices more than their children can imagine. Still, I was one of the lucky ones. While my parents were able to travel for my medical needs, thousands of families are less fortunate. That is why I founded Aashray Charities. A nonprofit organization that helps families with hospitalized children find a private place to live so they can rest, reboot, and recharge; because we at Aashray believe that by improving the well-being families, we can improve the well-being of their children as well.
                    </p>
                </div>
                <div className="space"></div>
            </section>
            <FooterComponent />
        </div >
    )
}
export default FounderStoryComponent;