import * as React from "react";
import FooterComponent from "../footer/footer";
import EventAndNewsComponent from './events-news';
import { Link } from "react-router-dom";
import NewsImages from "../../json/news_list.json";
import Slider from "../home/slider";

const NewsComponent = (props: any) => {
    const myRef = React.useRef<HTMLDivElement>(null);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 769 && window.innerWidth < 1024);
    const [isBigScreen, setIsBigScreen] = React.useState(window.innerWidth > 1330);
    const [isLessMobile, setIsLessMobile] = React.useState(window.innerWidth < 768);

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const istablet = window.innerWidth > 769 && window.innerWidth < 1024;
            const islessmobile = window.innerWidth < 768;
            const isbigScreen = window.innerWidth > 1330;
            if (istablet !== isTablet) {
                setIsTablet(istablet);
            } else if (isLessMobile !== islessmobile) {
                setIsLessMobile(islessmobile)
            } else {
                setIsBigScreen(isbigScreen)
            }
        }, false);

        if (myRef && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: "center"
            });
        }
    }, [isTablet, isLessMobile, isBigScreen])

    return (
        <div className="main-body">
            <EventAndNewsComponent />
            <div className='aboutUs marginBottom10'>
                {
                    isLessMobile || isTablet ? (
                        isLessMobile ? (<>
                            <div className="row">
                                <div className='col-sm-12'>
                                    <div className="card">
                                        <div className='card-body'>
                                            <>
                                                {
                                                    NewsImages.NewsImages.map((x, index) => {
                                                        return <>
                                                            <div key={index} className="slideshowMobile">
                                                                <div className="NewsImages">
                                                                    <Slider images={x.Images} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    })
                                                }
                                            </>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="WhatWeDo">
                                        <div className="side p40 flex">
                                            <span className='p40 backgroundColorDefault fontSize16 textAlignCenter fontFamilyTimesNewRoman SpacePadding10'>
                                                <b>HOT OFF THE PRESS!!!</b> We are thrilled to announce the addition of The Children's Hospital at Saint Peter's University Hospital to the increasing list of children's hospitals Aashray is proud to serve!
                                            </span>
                                        </div>
                                        <div className="side p40 paddingTop10 flex">
                                            <span className='p40 backgroundColorDefault fontSize16 textAlignCenter fontFamilyTimesNewRoman SpacePadding10'>
                                                The hospital staff do great things for the families at Saint Peter's. Not only do they help heal children, but The Children's Hospital is especially effective at helping neonatology families. In fact, their NICU is equipped with 54 dedicated bassinets! Not to mention, Aashray Charities Inc. Trustee Dan Boyle was born there!
                                            </span>
                                        </div>
                                        <div className="side p40 paddingTop10 flex">
                                            <span className='p40 backgroundColorDefault fontSize16 textAlignCenter fontFamilyTimesNewRoman SpacePadding10'>
                                                Aashray was lucky enough to meet a few social workers and some foundation personnel, and we were left astounded by their dedication and devotion to the Children's Hospital and its patients. If you ever need infant care or are battling childhood illness, The Children's Hospital is a great decision.
                                            </span>
                                        </div>

                                        <div className="side p40 paddingTop10 flex">
                                            <span className='p40 backgroundColorDefault fontSize16 textAlignCenter fontFamilyTimesNewRoman SpacePadding10'>
                                                 We hope to build on this relationship quickly and provide our beneficiaries with more no-cost nights, but we can only do that with your help! So please consider <Link to="/donation">donating</Link> to continue increasing the number of families served.
                                            </span>
                                        </div>
                                        <div className="side p40 paddingTop10 flex">
                                            <span className='p40 backgroundColorDefault fontSize16 textAlignCenter fontFamilyTimesNewRoman SpacePadding10'>
                                                 Of course, we couldn't do what we do without the help of Home2Suites New Brunswick. Emma, the Director of Sales & Marketing at the hotel has been instrumental in this partnership. If you are ever in the Central New Jersey area and need a place to stay and unwind, check them out! 
                                            </span>
                                        </div>
                                        <div className="side p40 paddingTop10 flex">
                                            <span className='p40 backgroundColorDefault fontSize16 textAlignCenter fontFamilyTimesNewRoman SpacePadding10'>
                                                 As always, stop by here to learn about any more noteworthy events, and if you want to stay in the know, drop us an email at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a> to stay updated on everything in our world!
                                            </span>
                                        </div>
                                        {/* <div className="side p40 paddingTop10 flex">
                                            <span className='p40 backgroundColorDefault fontSize16 textAlignCenter fontFamilyTimesNewRoman SpacePadding10'>
                                                Please check back here to learn just about any other noteworthy and important event going on with us. And if you want to stay in the know, drop us an email at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a> to stay updated with everything going on in our world!
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </>) : (

                            <div className="">
                            <div className="floatright">
                                <>
                                    {
                                        NewsImages.NewsImages.map((x, index) => {
                                            return <>
                                                <div key={index} className="slideshow">
                                                    <div className="NewsImages">
                                                        <Slider images={x.Images} />
                                                    </div>
                                                </div>
                                            </>
                                        })
                                    }
                                </>
                            </div>
                            <p className="p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault">
                                 <b>HOT OFF THE PRESS!!!</b> We are thrilled to announce the addition of The Children's Hospital at Saint Peter's University Hospital to the increasing list of children's hospitals Aashray is proud to serve!
                            </p>
                            <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                The hospital staff do great things for the families at Saint Peter's. Not only do they help heal children, but The Children's Hospital is especially effective at helping neonatology families. In fact, their NICU is equipped with 54 dedicated bassinets! Not to mention, Aashray Charities Inc. Trustee Dan Boyle was born there!
                            </p>
                            <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                Aashray was lucky enough to meet a few social workers and some foundation personnel, and we were left astounded by their dedication and devotion to the Children's Hospital and its patients. If you ever need infant care or are battling childhood illness, The Children's Hospital is a great decision.
                            </p>
                            <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                We hope to build on this relationship quickly and provide our beneficiaries with more no-cost nights, but we can only do that with your help! So please consider <Link to="/donation">donating</Link> to continue increasing the number of families served.
                            </p>
                            <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                Of course, we couldn't do what we do without the help of Home2Suites New Brunswick. Emma, the Director of Sales & Marketing at the hotel has been instrumental in this partnership. If you are ever in the Central New Jersey area and need a place to stay and unwind, check them out! 
                            </p>
                            <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                As always, stop by here to learn about any more noteworthy events, and if you want to stay in the know, drop us an email at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a> to stay updated on everything in our world!
                            </p>
                            {/* <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                Please check back here to learn just about any other noteworthy and important event going on with us. And if you want to stay in the know, drop us an email at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a> to stay updated with everything going on in our world!
                            </p> */}
                            </div>
                        )
                    ) : (
                        <div className="">
                            <div className="floatright">
                                <>
                                    {
                                        NewsImages.NewsImages.map((x, index) => {
                                            return <>
                                                <div key={index} className="slideshow">
                                                    <div className="NewsImages">
                                                        <Slider images={x.Images} />
                                                    </div>
                                                </div>
                                            </>
                                        })
                                    }
                                </>
                            </div>
                            <p className="p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault">
                                <b>HOT OFF THE PRESS!!!</b> We are thrilled to announce the addition of The Children's Hospital at Saint Peter's University Hospital to the increasing list of children's hospitals Aashray is proud to serve!
                            </p>
                            <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                The hospital staff do great things for the families at Saint Peter's. Not only do they help heal children, but The Children's Hospital is especially effective at helping neonatology families. In fact, their NICU is equipped with 54 dedicated bassinets! Not to mention, Aashray Charities Inc. Trustee Dan Boyle was born there!
                            </p>
                            <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                Aashray was lucky enough to meet a few social workers and some foundation personnel, and we were left astounded by their dedication and devotion to the Children's Hospital and its patients. If you ever need infant care or are battling childhood illness, The Children's Hospital is a great decision.
                            </p>
                            <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                We hope to build on this relationship quickly and provide our beneficiaries with more no-cost nights, but we can only do that with your help! So please consider <Link to="/donation">donating</Link> to continue increasing the number of families served.
                            </p>
                            <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                 Of course, we couldn't do what we do without the help of Home2Suites New Brunswick. Emma, the Director of Sales & Marketing at the hotel has been instrumental in this partnership. If you are ever in the Central New Jersey area and need a place to stay and unwind, check them out!
                            </p>
                            <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                As always, stop by here to learn about any more noteworthy events, and if you want to stay in the know, drop us an email at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a> to stay updated on everything in our world!
                            </p>
                            {/* <p className='p40 padding10 aboutUsFontSize fontSize22 nextFontSize textAlignLeft fontFamilyTimesNewRoman backgroundColorDefault'>
                                Please check back here to learn just about any other noteworthy and important event going on with us. And if you want to stay in the know, drop us an email at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a> to stay updated with everything going on in our world!
                            </p> */}
                        </div>
                    )
                }
            </div>
            <FooterComponent />
        </div>
    )
}
export default NewsComponent;