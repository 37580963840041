import * as React from 'react';
import { Images,Hotel } from '../../helper/interfaces';

const delay = 2500;
const HomeHotelSlideComponent = (props: any) => {
    const images: Images[] = props.images;
    const hotel:Hotel = props.Name;
    const [slideshowDotIndex, setSlideshowDotIndex] = React.useState(0);
    const slideshowDotIndexTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(null);

    function resetTimeout() {
        if(slideshowDotIndexTimeout.current){
            clearTimeout(slideshowDotIndexTimeout.current)
        }
    }
    React.useEffect(() => {
        slideshowDotIndexTimeout.current = setTimeout(
            () =>
                setSlideshowDotIndex((prevIndex) =>
                    prevIndex === images.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );
        return () => {
            resetTimeout();
        };
    },[slideshowDotIndex, images]);
    return (
        <div className="slideshowHotels">
            <div className="row">
            <span className='missionText fontSize26 textDecorationUnderline'>{hotel.PreHotelName}</span>
            <span className='missionText fontSize24'>{hotel.PostHotelName}</span>
        </div>
            <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-slideshowDotIndex * 100}%, 0, 0)` }}
            >
                {
                    (images) ? (
                        images.map((x, index) => {
                            return (
                                <div className="slide" key={"slide" + index}>
                                    <img className='hotelImages' alt={x.url} src={x.url} />
                                </div>
                            )
                        })
                    ) : (<></>)
                }
            </div>
            <div className="slideshowDots">
                {images.map((_, idx) => {
                    return (
                        <div
                            key={"slideshowDots" + idx}
                            className={`slideshowDot${slideshowDotIndex === idx ? " active" : ""}`}
                            onClick={() => {
                                setSlideshowDotIndex(idx);
                            }}
                        >
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default HomeHotelSlideComponent