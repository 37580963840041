import * as React from 'react';
import NavbarComponent from '../navbar/navbar';
import SocialMediaComponent from '../social-media/social-media';
import { Box, Tab, Tabs } from "@mui/material";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'


const WhoWeAreComponent = () => {
  
  const location = useLocation();
  // const classes = useStyles;
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1200);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile])
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div className="main-body">
          <NavbarComponent />
          <section className="section">
          <div className="hero--text textColorBlack marginTop50"><h1 className="h10 hero--header">Who We Are</h1></div>
            <div className="tabControl">
              <Tabs value={location.pathname} variant="scrollable"
                allowScrollButtonsMobile >
                <Tab className="text-eyebrow-cw-secondary fontSize18 block spaceLeft" 
                  label="About Us" 
                  value="/who-we-are" 
                  component={Link} 
                  to='/who-we-are' />
                  <Tab className="text-eyebrow-cw-secondary fontSize18 block spaceLeft"
                  label="Founder's Story" 
                  value="/founder-story" 
                  component={Link} 
                  to='/founder-story' />
                <Tab className="text-eyebrow-cw-secondary fontSize18 block spaceLeft" 
                  label="Our Board" 
                  value="/our-board" 
                  component={Link} 
                  to="/our-board" />
                <Tab className="text-eyebrow-cw-secondary fontSize18 block spaceLeft"
                  value="/our-staff"
                  label="Our Staff"
                  component={Link}
                  to='/our-staff'
                />
              </Tabs>
            </div>
          </section>
        </div>
      </Box>
    </Box>
  )
}
export default WhoWeAreComponent;