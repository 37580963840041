import * as React from 'react';
import { Images } from '../../helper/interfaces';

const delay = 6500;
const Slider = (props: any) => {
    const images: Images[] = props.images;
    // var Start=0;
    const [slideshowDotIndex, setSlideshowDotIndex] = React.useState(0);
    const slideshowDotIndexTimeout = React.useRef<null | ReturnType<typeof setTimeout>>(null);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 577 && window.innerWidth < 1024);
    const [isLessMobile, setIsLessMobile] = React.useState(window.innerWidth <= 577);
    const [isBigScreen, setIsBigScreen] = React.useState(window.innerWidth > 1330);

    function resetTimeout() {
        if(slideshowDotIndexTimeout.current){
            clearTimeout(slideshowDotIndexTimeout.current)
        }
    }
    React.useEffect(() => {
        
        slideshowDotIndexTimeout.current = setTimeout(
            () =>
                setSlideshowDotIndex((prevIndex)=>
                    prevIndex === images.length - 1 ? 0 : prevIndex +1
                ),
            delay
        );
        return () => {
            resetTimeout();
        };
    },[slideshowDotIndex, images]);
React.useEffect(() => {
        window.addEventListener("resize", () => {
            const istablet = window.innerWidth > 577 && window.innerWidth < 1024;
            const islessmobile = window.innerWidth <= 577;
            const isbigScreen = window.innerWidth > 1330;
            if (istablet !== isTablet) {
                setIsTablet(istablet);
            } else if (isLessMobile !== islessmobile) {
                setIsLessMobile(islessmobile)
            } else {
                setIsBigScreen(isbigScreen)
            }
        }, false);
    }, [isTablet, isLessMobile, isBigScreen])
    return (
        <>
            {isTablet || isLessMobile ? (<div className="slideshowMobile">
            <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-slideshowDotIndex * 100}%, 0, 0)` }}
            >
                {
                    (images) ? (
                        images.map((x, index) => {
                            return (
                                <div className="slide" key={"slide" + index}>
                                    <img className='imageDisplay' alt={x.url} src={x.url} />
                                </div>
                            )
                        })
                    ) : (<></>)
                }
            </div>
            <div className="slideshowDots">
                {images.map((_, idx) => {
                    return (
                        <div
                            key={"slideshowDots" + idx}
                            className={`slideshowDot${slideshowDotIndex === idx ? " active" : ""}`}
                            onClick={() => {
                                setSlideshowDotIndex(idx);
                            }}
                        >
                        </div>
                    )
                })}
            </div>
        </div>) : (<div className="slideshow">
            <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-slideshowDotIndex * 100}%, 0, 0)` }}
            >
                {
                    (images) ? (
                        images.map((x, index) => {
                            return (
                                <div className="slide" key={"slide" + index}>
                                    <img className='imageDisplay' alt={x.url} src={x.url} />
                                </div>
                            )
                        })
                    ) : (<></>)
                }
            </div>
            <div className="slideshowDots">
                {images.map((_, idx) => {
                    return (
                        <div
                            key={"slideshowDots" + idx}
                            className={`slideshowDot${slideshowDotIndex === idx ? " active" : ""}`}
                            onClick={() => {
                                setSlideshowDotIndex(idx);
                            }}
                        >
                        </div>
                    )
                })}
            </div>
        </div>)}
        </>
    )
}
export default Slider