import * as React from "react";
import { CircularProgress } from "@mui/material";
import { JotFormDetails } from "../../helper/interfaces";
import JotFormDetail from "../../json/online-application-jotFormDetails.json";

const JotForm = () => {
    const [loading, setLoading] = React.useState(true);
    const jotFormUrlInfo: JotFormDetails = JotFormDetail;
    React.useEffect(() => {
        let abortController = new AbortController();
        setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => {
            abortController.abort();
        }
    }, [])
    return (
        <div className="applicationForm displaySpinCenter">
            {
                loading ? (
                    <CircularProgress className="circularSpin spinCenter" />
                ) : (
                    <iframe
                        id={(jotFormUrlInfo.JotFormText) ? (jotFormUrlInfo.JotFormText + Number(jotFormUrlInfo.JotFormId)) : ""}
                        title="Accommodation"
                        onLoad={(e: any) => {
                            "window.parent.scrollTo(0,0)"
                        }}
                        allowFullScreen={true}
                        allow="geolocation; microphone; camera"
                        src={`${jotFormUrlInfo.JotFormUrl}${jotFormUrlInfo.JotFormId}`}
                        frameBorder={0}
                        style={{ minWidth: '100%', height: "100%", border: 'none' }}
                        scrolling="yes"
                    >
                    </iframe>
                )
            }
        </div>
    )
}
export default JotForm;