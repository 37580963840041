import * as React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../Images/Aashray.png";
import { GoogleFormData } from "../../helper/interfaces";
import GoogleForm from "../../json/google-forms.json";
import { Card, CardContent } from '@mui/material';
import { Facebook, Instagram, Twitter, Email } from '@mui/icons-material';
import TwitterImage from "../../Images/twitter_icon.png";

const NavbarComponent = () => {
    const googleFormData: GoogleFormData = GoogleForm;
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1200);
    const [googleFormUrl, setGoogleFormUrl] = React.useState("");
    const [redFlesh, setRedFlesh] = React.useState(true);
    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
        setGoogleFormUrl(`${googleFormData.BaseUrl}${googleFormData.AdditionalUrl}${googleFormData.FormData}${googleFormData.Embedded}`);

        const intervalID = setTimeout(() => {
            setRedFlesh(redFlesh => !redFlesh);
          }, 500);
          return () => clearInterval(intervalID);
    }, [googleFormData, isMobile, redFlesh])

    return (
        <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img className='logoImage' src={logo} alt="AashrayCharity Logo" /> <span className='charityName'>Aashray Charities Inc.</span>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="aboutDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Who We Are
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="aboutDropdown">
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent className='cardContextNavbar'>
                                        <li><Link className="block underline font-medium transition-opacity opacity-75 hover-opacity-100 text-cw-navy hover-bg-transparent px-3 py-0 mt-1 dropdown-item" to="/who-we-are">About Us</Link></li>
                                        <li><Link className="block underline font-medium transition-opacity opacity-75 hover-opacity-100 text-cw-navy hover-bg-transparent px-3 py-0 mt-1 dropdown-item" to="/founder-story">Founder's Story</Link></li>
                                        <li><Link className="block underline font-medium transition-opacity opacity-75 hover-opacity-100 text-cw-navy hover-bg-transparent px-3 py-0 mt-1 dropdown-item" to="/our-board">Our Board</Link></li>
                                        <li><Link className="block underline font-medium transition-opacity opacity-75 hover-opacity-100 text-cw-navy hover-bg-transparent px-3 py-0 mt-1 dropdown-item" to="/our-staff">Our Staff</Link></li>
                                    </CardContent>
                                </Card>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/what-we-do">What We Do</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${redFlesh ? "redFleshColor" : ""}`} to="/news">News and Events</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact-us">Contact Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/get-involved">Get Involved</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/donation">Donate</Link>
                        </li>
                    </ul>

                    <div className="d-flex">
                        <ul>
                            <li className="nav-item">
                                <a rel="noopener noreferrer" href="https://www.facebook.com/aashraycharities/" target="_blank" className="nav-link Facebook">
                                    <Facebook className="fab fa-facebook-f" />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a rel="noopener noreferrer" href="https://www.instagram.com/aashraycharities/" target="_blank" className="nav-link Instagram">
                                    <Instagram className="fab fa-instagram" />
                                </a>
                            </li>

                            <li className="nav-item">
                                <a rel="noopener noreferrer" href="https://twitter.com/aashray_inc" target="_blank" className="nav-link Twitter">
                                    {/* <Twitter className="fab fa-twitter" /> */}
                                    <img src={TwitterImage} alt="twitter" className="twitterIcon" />
                                </a>
                            </li>

                            <li className="nav-item">
                                <a rel="noopener noreferrer" href={googleFormUrl} target="_blank" className="nav-link Google">
                                    <Email className="fab fa-envelope fa fa-envelope-o" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}
export default NavbarComponent
