import * as React from "react";
import NavbarComponent from "../navbar/navbar";
import SocialMediaComponent from '../social-media/social-media';
import { Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const EventAndNewsComponent = (props: any) => {
    const location = useLocation();
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1200);
    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile])
    return (
        <section className='section'>
            <div className="main-body">
                <NavbarComponent />
                <section className="section">
                    <div className="tabControl">
                        <Tabs className={isMobile?"fontSize18":"fontSize15"} value={location.pathname} variant="scrollable"
                            allowScrollButtonsMobile>
                            <Tab className="text-eyebrow-cw-secondary block spaceLeft" label="News" value="/news" component={Link} to="/news" />
                            <Tab className="text-eyebrow-cw-secondary block spaceLeft" label="Events" value="/events" component={Link} to="/events" />
                        </Tabs>
                    </div>
                </section>
            </div>
        </section>
    )
}
export default EventAndNewsComponent;