import * as React from 'react';
import { GoogleFormData } from '../../helper/interfaces';
import GoogleForm from "../../json/google-forms.json";
import { Facebook, Google, Instagram, Twitter } from '@mui/icons-material';

const SocialMediaComponent = () => {
    const googleFormData: GoogleFormData = GoogleForm;
    const [googleFormUrl, setGoogleFormUrl] = React.useState("");

    React.useEffect(() => {
        setGoogleFormUrl(`${googleFormData.BaseUrl}${googleFormData.AdditionalUrl}${googleFormData.FormData}${googleFormData.Embedded}`)
    }, [googleFormData])
    return (
        <div className="sticky-icon">
            <a rel="noopener noreferrer" href="https://www.facebook.com/aashraycharities/" target="_blank" className="Facebook">
                <Facebook className="fab fa-facebook-f" />  Follow Us
            </a>
            <a rel="noopener noreferrer" href="https://www.instagram.com/aashraycharities/" target="_blank" className="Instagram">
                <Instagram className="fab fa-instagram"/> Follow Us
            </a>
            <a rel="noopener noreferrer" href="https://twitter.com/aashray_inc" target="_blank" className="Twitter">
                <Twitter className="fab fa-twitter" /> Follow Us
            </a>
            <a rel="noopener noreferrer" href={googleFormUrl} target="_blank" className="Google">
                <Google className="fab fa-envelope fa fa-envelope-o" /> Subscribe
            </a>
        </div>
    )
}
export default SocialMediaComponent;