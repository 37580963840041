import { Tooltip } from "@mui/material";
import * as React from "react";
import { Link } from 'react-router-dom';
import listOfBoard from "../../json/board-of-directors.json";
import duration from "../../json/trustee-Year.json";
import FooterComponent from "../footer/footer";
import WhoWeAreComponent from "./who_we_are";

const OurBoardComponent = (props: any) => {
    const myRef = React.useRef<HTMLDivElement>(null);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 576 && window.innerWidth < 921);
    const [isBigScreen, setIsBigScreen] = React.useState(window.innerWidth > 1330);
    const [isLessMobile, setIsLessMobile] = React.useState(window.innerWidth <= 576);

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const istablet = window.innerWidth > 576 && window.innerWidth < 921;
            const islessmobile = window.innerWidth <= 576;
            const isbigScreen = window.innerWidth > 1330;
            if (istablet !== isTablet) {
                setIsTablet(istablet);
            } else if (isLessMobile !== islessmobile) {
                setIsLessMobile(islessmobile)
            } else {
                setIsBigScreen(isbigScreen)
            }
        }, false);

        if (myRef && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: "center"
            });
        }
    }, [isTablet, isLessMobile, isBigScreen])
    return (
        <div className="main-body">
            <WhoWeAreComponent />
            <section className='section'>
                <div className="card">
                    <div className="card-body">
                        {
                            (duration) ? (
                                duration.map((text, index) => {
                                    return <div key={index} className="card">
                                        <div className="row marginTop20">
                                            <div className="col-sm-12 alignTextCenter">
                                                <div className="card-header defaultHeaderColor textAlignCenter">
                                                    <span className='founderStorySpan textDecorationUnderline fontWeightBold'>{text.Duration}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            ) : (<></>)
                        }
                        <div className="boardOfDirectors">
                            <div className="space">
                                {
                                    isTablet || isLessMobile ? (
                                        (listOfBoard) ? (
                                            listOfBoard.map((text, index) => {
                                                return (
                                                    <div key={index} className="row space">
                                                        <div className={`${isLessMobile ? "col-sm-4 imageDiv" : 'col-sm-4 imageDiv'}`} >
                                                            <Tooltip title={`${text.Role}`}>
                                                                <img className={`${isLessMobile ? 'img-responsive boardOfDirectorsImage' : "img-responsive boardOfDirectorsImage paddingLeft20"}`} src={text.Image} alt="boardOfDirector" />
                                                            </Tooltip>
                                                        </div>

                                                        <div className={`${isLessMobile ? "col-sm-8 imageDivText" : "col-sm-8 imageDivText"}`}>
                                                            <div className={`${isLessMobile ? "boardTusteeRole" : "boardTusteeRole"}`}>
                                                                <div className="spacePaddingTop20"></div>
                                                                <span className="fontSize30 Uniting_Families_So_They_Can_B_defaultColor defaultOtherFormator">{text.Name}</span>
                                                            </div>
                                                            <div id="modal-modal-description" className={`${isLessMobile ? "textAlignCenterMobile fontFamilyTimesNewRoman" : "fontFamilyTimesNewRoman"}`}>
                                                                <span className="p40 fontChange colorBlack">{text.Description}
                                                                    {
                                                                        (text.Name === "Samarth Mehta") ? (
                                                                            <Link to="/founder-story" className="onlineApplication">founder's story</Link>
                                                                        ) : (<></>)
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : (<></>)) : (
                                        (listOfBoard) ? (
                                            listOfBoard.map((text, index) => {
                                                return (
                                                    <div key={index} className="row space">
                                                        <div className='col-sm-3'>
                                                            <Tooltip title={`${text.Role}`}>
                                                                <img className='img-responsive boardOfDirectorsImage paddingLeft20' src={text.Image} alt="boardOfDirector" />
                                                            </Tooltip>
                                                        </div>

                                                        <div className="col-sm-7">
                                                            <div className="boardTusteeRole">
                                                                <span className="fontSize30 Uniting_Families_So_They_Can_B_defaultColor defaultOtherFormator">{text.Name}</span>
                                                            </div>
                                                            <div id="modal-modal-description" className="textAlignLeft fontFamilyTimesNewRoman">
                                                                <span className="p40 fontChange colorBlack">{text.Description}
                                                                    {
                                                                        (text.Name === "Samarth Mehta") ? (
                                                                            <Link to="/founder-story" className="onlineApplication">founder's story</Link>
                                                                        ) : (<></>)
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : (<></>)
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterComponent />
        </div>
    )
}
export default OurBoardComponent;