import * as React from "react";
import { Link } from "react-router-dom";
import FooterComponent from "../footer/footer";
import AccommodationComponent from "./accommodations";
import HomeHotelSlideComponent from '../home/home-hotel-slide';
import hotels from "../../json/hotel-image.json";

const AccommodationsRequirementComponent = () => {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1200);
    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile])
    return (
        <div className="accommodationMainBody main-body">
            <AccommodationComponent />
            <div className="card accommodationRequirement textAlignCenter">
                <div className="card-body">
                    <div className="row"><div className="col-sm-12 alignTextCenter">
                        <div className="card-header defaultHeaderColor">
                            <span className='founderStorySpan textCapitalize textDecorationUnderline fontWeightBold'> Accommodation Details, Eligibility Criteria & Other Important Information </span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row"><div className="col-sm-12 alignTextCenter">
                        <div className="card-header defaultHeaderColor">
                            <span className="fa fa-star checked"></span><span className='AccommodationSubSpan textCapitalize textDecorationUnderline fontWeightBold'> Accommodation Details: </span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card  marginLeft60">
                <div className="card-body">
                    <table className="marginLeft20">
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>Accommodations will be provided based on a first come first serve basis with priority assessed on our capacity to accommodate families, income level, and the distance traveled to obtain treatment.</span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>Accommodations include only those facilities provided by the hotel. Aashray does not provide food or transportation. However, as a guest of a hotel you can take advantage of any of the amenities provided by the hotel which are included in the tariff, such as, a complimentary breakfast, swimming pool, exercise room, etc.</span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>The number of guests per room will be determined by the room configuration, availability, and the hotel’s policy.</span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>Each stay is restricted to a maximum of one week, after which families must reapply for accommodations. However, exceptions may be made depending on each individual case and our capacity at that moment.</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="row"><div className="col-sm-12 alignTextCenter">
                        <div className="card-header defaultHeaderColor">
                            <span className="fa fa-star checked"></span><span className='AccommodationSubSpan textCapitalize textDecorationUnderline fontWeightBold'> Eligibility Requirements: </span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card marginLeft60">
                <div className="card-body">
                    <table className="marginLeft20">
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>Accommodations will be provided to anyone who is an important support person (relative/friend) to a patient 18 years or younger who is suffering from an illness that requires hospitalization or active treatment (two or more visits to a hospital per week.)</span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>Accommodations will be provided to only those families that have filled out an <Link className="onlineApplication" to="/online-application">application form </Link></span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>Accommodations will be provided to only those families whose child, 18 years or younger, has been confirmed as a patient at a partner hospital.</span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>Accommodations will be provided to only those families who have a permanent home to return to after their stay.</span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>Aashray Charities Inc. requires that each patient, if a guest of the hotel, also be accompanied by an adult guest.</span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>Only those families who will not damage or harm the hotel or its guests will receive accommodations.</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div className="card marginLeft60">
                <div className="card-body">
                    <div className="row"><div className="col-sm-12 alignTextCenter">
                        <div className="card-header defaultHeaderColor">
                            <span className='pleaseNoteSpan textCapitalize textDecorationUnderline fontWeightBold'> Please Note: </span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card marginLeft120">
                <div className="card-body">
                    <table className="marginLeft20">
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-square fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>Pets or service animals will be allowed depending on the hotel’s policies. (You will be notified of the policy prior to placement.)</span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-square fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>The hotel you are staying at will need your credit card information on file. </span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-square fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>You will be charged for all incidental and other charges not provided by Aashray Charities Inc.</span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-square fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>You will be charged for any damage/harm inflicted on the hotel or its guests.  </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            
            <div className="card">
                <div className="card-body">
                    <div className="row"><div className="col-sm-12 alignTextCenter">
                        <div className="card-header defaultHeaderColor">
                            <span className="fa fa-star checked"></span><span className='AccommodationSubSpan textCapitalize textDecorationUnderline fontWeightBold'> Application/Placement Process: </span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card marginLeft60">
                <div className="card-body">
                    <table className="marginLeft20">
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>You will need to complete an application <Link className="onlineApplication" to="/online-application">online</Link></span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>After completing the application, you will receive an email to confirm that we have received it.</span>
                            </td>
                        </tr>
                        <tr className="marginBottom10">
                            <td className="verticalAlignBaseLine">
                                <span className="fa-solid fa-circle fontSize10 defaultBackgroundColor"></span>
                            </td>
                            <td className="paddingLeft10">
                                <span className={isMobile ? "bulletPointFontSize fontSize15" : "bulletPointFontSize"}>If you have any questions, you can call Aashray at 732-354-1881 or email us at <a href="mailto:info@aashraycharities.org">info@aashraycharities.org</a></span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="row"><div className="col-sm-12 alignTextCenter">
                        <div className="card-header defaultHeaderColor">
                            <span className="fa fa-star checked"></span><span className='AccommodationSubSpan textCapitalize textDecorationUnderline fontWeightBold'> Confirming Your Stay: </span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <table className="marginLeft20">
                        <tr className="marginBottom10">
                            <td className="paddingLeft10 paddingRight10">
                                <p className='textIndent30 missionTextAlignmentLeft bulletPointFontSize'>Although you may apply at any time, we have limited rooms available and families are placed on a first-come, first-served basis. We try our best to confirm your accommodations as soon as possible. We will notify you as soon as we hear back from a hotel, however the timing is not predictable. Nonetheless, once we confirm your placement, we will provide you with the hotel name, location, and reservation details by email.</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row"><div className="col-sm-12 alignTextCenter">
                        <div className="card-header defaultHeaderColor">
                            <span className="fa fa-star checked"></span><span className='AccommodationSubSpan textCapitalize textDecorationUnderline fontWeightBold'> Donation: </span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <table className="marginLeft20">
                        <tr className="marginBottom10">
                            <td className="paddingLeft10">
                                <p className='textIndent30 missionTextAlignmentLeft bulletPointFontSize'> Our suggested donation is $25.00 a night or whatever you wish. However, if you are unable to contribute, the room is at no-cost. You can donate via check at the end of your stay or on Aashray’s website.</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            
            <div className="card">
                <div className="card-body">
                    <div className="row"><div className="col-sm-12 alignTextCenter">
                        <div className="card-header defaultHeaderColor">
                            <span className='founderStorySpan textCapitalize textDecorationUnderline fontWeightBold'> Participating Hotels </span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        {
                            hotels.Hotels.map((x, index) => {
                                return <div key={index} className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <HomeHotelSlideComponent Name={x} images={x.Images} />
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>

            <FooterComponent />
        </div>
    )
}
export default AccommodationsRequirementComponent;