import * as React from 'react';
import { Link } from 'react-router-dom';
import NotFoundImage from "../../Images/not-found.png"

const PageNotFound = () => {
    return (
        <div className="main-body whoopsPage">
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="row"><span className="whoops fontSize30 textAlignCenter defaultColor"> Whoops! </span></div>
                            <div className="row"> <span className="404PageMessage fontSize25 textAlignCenter defaultColor margin30top"> 404 Page Not Found or Under Maintance </span></div>
                        </div>
                        <div className="col-sm-4">
                            <div id="over" className="whatwedoDiv">
                                <img src={NotFoundImage} alt="404" className="img-responsive" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body margin30bottom textAlignCenter">
                    <span className="backhome fontSize20 defaultColor">Let's Go Back to <Link className="onlineapplication" to="/">home</Link></span>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;