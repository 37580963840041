import * as React from 'react';
import { Link } from "react-router-dom";

import { GoogleFormData } from '../../helper/interfaces';
import GoogleForm from "../../json/google-forms.json";
import { Email, EmailTwoTone, Phone } from '@mui/icons-material';

const FooterComponent = () => {
    const googleFormData: GoogleFormData = GoogleForm;
    const [googleFormUrl, setGoogleFormUrl] = React.useState("");

    const handleLaunchGooglePage = () => {
        window.open(googleFormUrl, "_blank", "width='640' height='771' frameborder='0' marginheight='0' marginwidth='0'")
    }

    React.useEffect(() => {
        setGoogleFormUrl(`${googleFormData.BaseUrl}${googleFormData.AdditionalUrl}${googleFormData.FormData}${googleFormData.Embedded}`);
    }, [googleFormData])
    return (
        <footer className="text-center text-lg-start text-white defaultbackgroundColorToBlue">
            <div className="container p-4 pb-0">
                <section className="">
                    <div className="row">
                        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto">
                            <p className="text-uppercase mb-4 textAlignLeft Uniting_Families_So_They_Can_B_WhiteColor Uniting_Families_So_They_Can_B textDecorationUnderline fontSize25"> Aashray Charities </p>
                            <p className="fontSize14 fontStyleItalic">
                                At Aashray Charities Inc., a 501(c)3, nonprofit organization, we understand that fighting childhood illness can be emotionally, physically, and financially expensive for families. Our goal is to provide some relief.
                            </p>
                        </div>

                        <hr className="w-100 clearfix d-md-none" />

                        <hr className="w-100 clearfix d-md-none" />
                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto">
                            <h6 className="text-uppercase mb-4 font-weight-bold footer-heading">
                                Who We Are
                            </h6>
                            <p>
                                <Link className="text-white linkFooter" itemProp="url" to="/who-we-are"> About Us </Link>
                            </p>
                            <p>
                                <Link className="text-white linkFooter" itemProp="url" to="/founder-story"> Founder's Story </Link>
                            </p>
                            <p>
                                <Link className="text-white linkFooter" itemProp="url" to="/our-board"> Our Board </Link>
                            </p>
                            <p>
                                <Link className="text-white linkFooter" itemProp="url" to="/our-staff"> Our Staff </Link>
                            </p>
                        </div>
                        <hr className="w-100 clearfix d-md-none" />
                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto">
                            <h6 className="text-uppercase mb-4 font-weight-bold footer-heading">What We Do</h6>
                            <p>
                                <Link className="text-white linkFooter" itemProp="url" to="/what-we-do"> What We Do </Link>
                            </p>
                        </div>
                        <hr className="w-100 clearfix d-md-none" />
                        

                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto">
                            <h6 className="text-uppercase mb-4 font-weight-bold footer-heading">
                                Get Involved
                            </h6>
                            <p>
                                <Link className="text-white linkFooter" itemProp="url" to="/donation"> Donate </Link>
                            </p>
                            <p>
                                <Link className="text-white linkFooter" itemProp="url" to="/get-involved"> Volunteer </Link>
                            </p>
                        </div>

                        <hr className="w-100 clearfix d-md-none" />
                        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto">
                            <h6 className="text-uppercase mb-4 font-weight-bold footer-heading"> Contact Us </h6>
                            <p className='text-right'><a className='emailBackGroundColor' href="mailto:info@aashraycharities.org"><EmailTwoTone />   info@aashraycharities.org </a></p>
                            <p className='text-right'><a className='emailBackGroundColor' href="mailto:aashraycharities@gmail.com"><EmailTwoTone />   aashraycharities@gmail.com</a></p>
                            <p className='text-right'><Phone />   +1 732-354-1881 </p>
                        </div>
                    </div>
                </section>

                {/* <hr className="my-1" />
                <section className="p-3 pt-0">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-7 col-lg-8 text-md-start">
                            <div className="p-3">
                            All Rights Reserved ©:<Link className="text-white" itemProp="url" to="/"><b>Aashray Charities</b></Link>
                            </div>
                        </div>

                        <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline-light btn-floating m-1 text-white"
                                href="https://www.facebook.com/aashraycharities"
                            ><img alt="facebookIcon" className='socialMediaIcon' src={facebookIcon} width="45" height="45" /></a>

                            <a
                                className="btn btn-outline-light btn-floating m-1 text-white"
                                target="_blank"
                                rel="noopener noreferrer"
                                href='https://www.instagram.com/aashraycharities/'
                            ><img alt="InstagramIcon" className='socialMediaIcon' src={InstagramIcon} width="45" height="45" /></a>
                            <a
                               target="_blank"
                               rel="noopener noreferrer"
                               className="btn btn-outline-light btn-floating m-1 text-white"
                               href="https://twitter.com/aashray_inc"
                            ><img alt="twitterIcon" className='socialMediaIcon' src={twitterIcon} width="45" height="45" /></a>
                            <button className="btn btn-outline-light btn-floating m-1 text-white" onClick={handleLaunchGooglePage}><img alt="EmailIcon" className='socialMediaIcon' src={EmailIcon} width="45" height="45" /></button>
                        </div>
                    </div>
                </section> */}
            </div>
        </footer>
    )
}
export default FooterComponent;