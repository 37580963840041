import * as React from "react";
import NavbarComponent from '../navbar/navbar';
import { QuotationsList } from '../../helper/interfaces';
import Quotations from "../../json/quotes.json";
import PaymentForm from "./payment-form";
import { PaymentGateWay } from "../../helper/interfaces"
import PaymentGateWayJson from '../../json/payment-app-type.json';
import FooterComponent from "../footer/footer";

const DonationComponent = (props: any) => {
    const PaymentGateWayUrl: PaymentGateWay = PaymentGateWayJson;
    const paymentUrl = `${PaymentGateWayUrl.BaseUrl}${PaymentGateWayUrl.PaymentAppName}`;
    const quotationsList: QuotationsList = Quotations.QuotationList;
    const myRef = React.useRef<HTMLDivElement>(null);
    // const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024 && window.innerWidth > 880);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth < 1024);
    const [isPC, setIsPC] = React.useState(window.innerWidth > 1023 && window.innerWidth < 1324);
    const [isTabletLess, setIsTabletLess] = React.useState(window.innerWidth > 576 && window.innerWidth < 768);
    const [isBigScreen, setIsBigScreen] = React.useState(window.innerWidth > 1725);
    const [isLessMobile, setIsLessMobile] = React.useState(window.innerWidth < 576);

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const istablet = window.innerWidth > 768 && window.innerWidth < 1024;
            const istabletLess = window.innerWidth > 576 && window.innerWidth < 768;
            const islessmobile = window.innerWidth <= 576;
            const ispC = window.innerWidth > 1023 && window.innerWidth < 1324
            const isbigScreen = window.innerWidth > 1725;
            if (istablet !== isTablet) {
                setIsTablet(istablet);
            } else if (isLessMobile !== islessmobile) {
                setIsLessMobile(islessmobile)
            } 
            else if (isTabletLess !== istabletLess) {
                setIsTabletLess(istabletLess)
            }
            else if (ispC !== isPC) {
                setIsPC(ispC)
            }else {
                setIsBigScreen(isbigScreen)
            }
        }, false);

        if (myRef && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: "center"
            });
        }
    }, [isTablet, isLessMobile, isBigScreen, isTabletLess,isPC])

    return (
        <div className="main-body">
            <NavbarComponent />
            {
                isLessMobile || isTablet || isTabletLess ? (
                    isLessMobile || isTabletLess ? (
                        <section className='section'>
                        <div className='whoWeAre main'>
                            <blockquote className="donationQuote otro-blockquote">
                                {quotationsList.DonationQuote.Quote}
                                <span className="quoteAuthor ">{quotationsList.DonationQuote.Author}</span>
                            </blockquote>
                            <div className="helpusText defaultColor marginTop30"><h1 className="helpusText textAlignCenter">Help Us Keep Families United and Cared For</h1></div>
                            <div className="card">
                                <div className="card-body accomodationcardDiv donationCardDiv">
                                    <div className="row">
                                        <div ref={myRef} id="myRef donationView" className="col-sm-12">
                                            <div className="paymentForm">
                                                <PaymentForm Name="Donation" paymentUrl={paymentUrl} />
                                                <div className="">
                                                    <span className="backgroundColorWhite defaultOtherFormator taxDedution borderNone textAlignCenter">All donations are tax-deductible – Tax ID# 87-2763667. </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="mainDonation defaultFont marginBottom40"></div>

                                            <div className="mainDonation defaultFont">
                                                <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                    Dear Patron,
                                                </span></p>
                                                <div className="space"></div>
                                                <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                    Through Aashray, I have found happiness and direction. Yet, I am by no means alone. Just talk to any of the families we serve, and they will tell you that they find an irreplicable happiness and peace by devoting everything to helping their children.
                                                </span></p>
                                                <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                    If you do choose to donate, whether it is $10 or $10,000, we at Aashray wholeheartedly thank you. And I hope that by giving, you will also find the same contentment I have discovered.
                                                </span></p>
                                            </div>

                                            <div className="mainDonation SincerelyText">
                                                <div className="mainDonation defaultFont">
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Sincerely,
                                                    </span></p>
                                                </div>
                                                <div className="mainDonation defaultFont">
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Samarth Mehta
                                                    </span></p>
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Founder
                                                    </span></p>
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Aashray Charities Inc.
                                                    </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    ):(
                        <section className='section'>
                        <div className='whoWeAre main'>
                            <blockquote className="donationQuote otro-blockquote textCenter">
                                {quotationsList.DonationQuote.Quote}
                                <span className="quoteAuthor ">{quotationsList.DonationQuote.Author}</span>
                            </blockquote>
                            <div className="helpusText defaultColor marginTop30"><h1 className="helpusText textAlignCenter">Help Us Keep Families United and Cared For</h1></div>
                            <div className="card">
                                <div className="card-body accomodationcardDiv donationCardDiv">
                                    <div className="row">
                                        <div ref={myRef} id="myRef donationView" className="col-sm-6">
                                            <div className="paymentForm">
                                                <PaymentForm Name="Donation" paymentUrl={paymentUrl} />
                                                <div className="textAlignCenter">
                                                    <span className="backgroundColorWhite defaultOtherFormator taxDedution borderNone textAlignCenter">All donations are tax-deductible – Tax ID# 87-2763667. </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mainDonation defaultFont marginBottom40"></div>

                                            <div className="mainDonation defaultFont">
                                                <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                    Dear Patron,
                                                </span></p>
                                                <div className="space"></div>
                                                <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                    Through Aashray, I have found happiness and direction. Yet, I am by no means alone. Just talk to any of the families we serve, and they will tell you that they find an irreplicable happiness and peace by devoting everything to helping their children.
                                                </span></p>
                                                <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                    If you do choose to donate, whether it is $10 or $10,000, we at Aashray wholeheartedly thank you. And I hope that by giving, you will also find the same contentment I have discovered.
                                                </span></p>
                                            </div>

                                            <div className="mainDonation SincerelyText">
                                                <div className="mainDonation defaultFont">
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Sincerely,
                                                    </span></p>
                                                </div>
                                                <div className="mainDonation defaultFont">
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Samarth Mehta
                                                    </span></p>
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Founder
                                                    </span></p>
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Aashray Charities Inc.
                                                    </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    )
                ) : (
                    <section className='section'>
                        <div className='whoWeAre main'>
                            <blockquote className="otro-blockquote donationQuote">
                                {quotationsList.DonationQuote.Quote}
                                <span className="quoteAuthor">{quotationsList.DonationQuote.Author}</span>
                            </blockquote>
                            <div className="helpusText defaultColor marginTop30"><h1 className="helpusText textAlignCenter">Help Us Keep Families United and Cared For</h1></div>
                            <div className="card">
                                <div className="card-body accomodationcardDiv donationCardDiv">
                                    <div className="row">
                                        <div ref={myRef} id="myRef donationView" className={`${isPC ? "col-sm-5": `${isBigScreen ? "col-sm-3":"col-sm-4"}`}`}>
                                            <div className="paymentForm margin20Left">
                                                <PaymentForm Name="Donation" paymentUrl={paymentUrl} />
                                                <div className="textAlignCenter marginTop10 margin20bottom">
                                                    <span className="backgroundColorWhite defaultOtherFormator taxDedution borderNone textAlignCenter">All donations are tax-deductible – Tax ID# 87-2763667. </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${isPC ? "col-sm-7": `${isBigScreen ? "col-sm-9":"col-sm-8"}`}`}>
                                            <div className="mainDonation defaultFont marginBottom40"></div>

                                            <div className="mainDonation defaultFont">
                                                <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                    Dear Patron,
                                                </span></p>
                                                <div className="space"></div>
                                                <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                    Through Aashray, I have found happiness and direction. Yet, I am by no means alone. Just talk to any of the families we serve, and they will tell you that they find an irreplicable happiness and peace by devoting everything to helping their children.
                                                </span></p>
                                                <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                    If you do choose to donate, whether it is $10 or $10,000, we at Aashray wholeheartedly thank you. And I hope that by giving, you will also find the same contentment I have discovered.
                                                </span></p>
                                            </div>

                                            <div className="mainDonation SincerelyText">
                                                <div className="mainDonation defaultFont">
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Sincerely,
                                                    </span></p>
                                                </div>
                                                <div className="mainDonation defaultFont">
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Samarth Mehta
                                                    </span></p>
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Founder
                                                    </span></p>
                                                    <p className="handTextPara"><span className="handTextSpan fontFamilyCaveat">
                                                        Aashray Charities Inc.
                                                    </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }

            <div className="getInvolveBehalf margin50bottom marginTop20 SpacePadding8">
                <span className='missionText fontSizeResponsive'>
                    If you are a hotel and would like to donate hotel accommodations for our beneficiaries email us at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a>
                </span>
            </div>

            <FooterComponent />
        </div>
    )
}
export default DonationComponent;