import * as React from "react";
import NavbarComponent from "../navbar/navbar";
import SocialMediaComponent from "../social-media/social-media";
import { Link, useLocation } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import { makeStyles } from '@mui/material/styles';
import { QuotationsList } from "../../helper/interfaces";
import Quotations from "../../json/quotes.json";

const AccommodationComponent = () => {
  const useStyles = makeStyles({
    scrollButtons: {
      "&.Mui-disabled": {
        opacity: 0.3
      }
    }
  });
  const quotationsList: QuotationsList = Quotations.QuotationList;
  const location = useLocation();
  const classes = useStyles;
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1200);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile])
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div className="main-body">
          <NavbarComponent />
          {
            (!isMobile) ? (<SocialMediaComponent />) : (<></>)
          }
          <section className="section">
            <blockquote className="otro-blockquote">
              {quotationsList.AccommodationQuote.Quote}
              <span>{quotationsList.AccommodationQuote.Author}</span>
            </blockquote>

            <div className="tabControl">
              <Tabs value={location.pathname} variant="scrollable"
                allowScrollButtonsMobile classes={classes}>
                <Tab className={!isMobile?"fontSize23":"fontSize15"} label="Accommodations" value="/accommodations" component={Link} to="/accommodations" />
                <Tab className={!isMobile?"fontSize23":"fontSize15"} label="Find Accommodations" value="/find-accommodations" component={Link} to="/find-accommodations" />
                <Tab className={!isMobile?"fontSize23":"fontSize15"}
                  value="/online-application"
                  label="Online Application"
                  component={Link}
                  to="/online-application"
                />
              </Tabs>
            </div>
          </section>
        </div>
      </Box>
    </Box>
  )
}
export default AccommodationComponent;