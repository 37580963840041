import * as React from 'react';
import { Link } from 'react-router-dom';
import FooterComponent from '../footer/footer';
import NavbarComponent from '../navbar/navbar';
import { QuotationsList } from '../../helper/interfaces';
import Quotations from "../../json/quotes.json";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HandRaiseImage from "../../Images/HandRaise.png"

const GetInvolvedComponent = () => {
    const quotationsList: QuotationsList = Quotations.QuotationList;
    const myRef = React.useRef<HTMLDivElement>(null);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 576 && window.innerWidth < 992);
    const [isLessMobile, setIsLessMobile] = React.useState(window.innerWidth < 576);

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const istablet = window.innerWidth > 576 && window.innerWidth < 992;
            const islessmobile = window.innerWidth <= 576;
            if (istablet !== isTablet) {
                setIsTablet(istablet);
                console.log(istablet);
            } else {
                setIsLessMobile(islessmobile)
                console.log(islessmobile);
            }
        }, false);

        if (myRef && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: "center"
            });
        }
    }, [isTablet, isLessMobile])
    return (
        <div className="main-body">
            <NavbarComponent />
            <section className='section'>
                {
                    isTablet || isLessMobile ? (
                        isLessMobile ? (
                            <>
                                <div className="container-fluid">
                                    <div className="hero--text textColorBlack"><h1 className="h10 hero--header">Get Involved</h1></div>
                                    <div className='whoWeAre main'>
                                        <blockquote className="otro-blockquote otro-blockquoteMobile textCenter">
                                            {quotationsList.GetInvolvedQuote.Quote}
                                            <span>{quotationsList.GetInvolvedQuote.Author}</span>
                                        </blockquote>
                                        <div id="box-search">
                                            <div className="thumbnail">
                                                <img src={HandRaiseImage} className='handRaiseImageOpacity HandeRaiseImage' alt=" HandRaiseImage " />
                                            </div>
                                            <div className="DivHandRaise">
                                                <div id="contain">
                                                    <p className="fontResponsive padding5Right padding10 backgroundColorDefault textCenter fontFamilyTimesNewRoman">So many children and their families need our support. But we can’t help them on our own. So we’re asking you to get involved! Whether you choose to donate or just donate your time, your kindness means everything. If you choose to volunteer, your help allows us to devote more resources to the families and hospitals we serve. Whether you choose to provide transportation, lend a hand, raise awareness, or help in a way we haven’t thought of yet, your compassion leaves a tremendous impact on our families!</p>
                                                </div>

                                                <ul className="list-group">
                                                    <li className="list-group-item justify-content-between backgroundColorDefault fontFamilyTimesNewRoman">
                                                        <FiberManualRecordIcon className="starRed" /><span className='fontResponsive'> For volunteer opportunities you can contact us at <a href="mailto:info@aashraycharities.org">info@aashraycharities.org</a> or call us at 732-354-1881</span>
                                                    </li>
                                                    <li className="list-group-item justify-content-between backgroundColorDefault fontFamilyTimesNewRoman">
                                                        <FiberManualRecordIcon className="starRed" /> <span className='fontResponsive'> If you can’t give your time don’t worry. You can donate to show your support. If you are an individual or corporation, you can raise funds by visiting our  <Link to="/donation"> donate page</Link></span>
                                                    </li>
                                                    <li className="list-group-item justify-content-between backgroundColorDefault fontFamilyTimesNewRoman">
                                                        <FiberManualRecordIcon className="starRed" /> <span className='fontResponsive'> If you are a hotel ready to offer your services or a hospital in need of support, you can email us at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a></span>
                                                    </li>
                                                </ul>

                                                <div className="getInvolveBehalf textAlignCenter marginTop20 missionText fontSize30 paddingBottom10"><span className="ShelteringSpan">On behalf of the families we serve, we thank you. </span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="container-fluid marginTop60">
                                    <div className="hero--text textColorBlack"><h1 className="h10 hero--header">Get Involved</h1></div>
                                    <div className='whoWeAre main'>
                                        <blockquote className="otro-blockquote textCenter">
                                            {quotationsList.GetInvolvedQuote.Quote}
                                            <span>{quotationsList.GetInvolvedQuote.Author}</span>
                                        </blockquote>
                                        <div id="box-search">
                                            <div className="thumbnail">
                                                <img src={HandRaiseImage} className='handRaiseImageOpacity HandeRaiseImage' alt=" HandRaiseImage " />
                                            </div>
                                            <div className="DivHandRaise">
                                                <div id="contain">
                                                    <p className="fontResponsive padding5Right backgroundColorDefault textCenter padding20 fontFamilyTimesNewRoman">So many children and their families need our support. But we can’t help them on our own. So we’re asking you to get involved! Whether you choose to donate or just donate your time, your kindness means everything. If you choose to volunteer, your help allows us to devote more resources to the families and hospitals we serve. Whether you choose to provide transportation, lend a hand, raise awareness, or help in a way we haven’t thought of yet, your compassion leaves a tremendous impact on our families!</p>
                                                </div>

                                                <ul className="list-group">
                                                    <li className="list-group-item justify-content-between backgroundColorDefault fontFamilyTimesNewRoman">
                                                        <FiberManualRecordIcon className="starRed" /><span className='fontResponsive'> For volunteer opportunities you can contact us at <a href="mailto:info@aashraycharities.org">info@aashraycharities.org</a> or call us at 732-354-1881</span>
                                                    </li>
                                                    <li className="list-group-item justify-content-between backgroundColorDefault fontFamilyTimesNewRoman">
                                                        <FiberManualRecordIcon className="starRed" /> <span className='fontResponsive'> If you can’t give your time don’t worry. You can donate to show your support. If you are an individual or corporation, you can raise funds by visiting our  <Link to="/donation"> donate page</Link></span>
                                                    </li>
                                                    <li className="list-group-item justify-content-between backgroundColorDefault fontFamilyTimesNewRoman">
                                                        <FiberManualRecordIcon className="starRed" /> <span className='fontResponsive'> If you are a hotel ready to offer your services or a hospital in need of support, you can email us at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a></span>
                                                    </li>
                                                </ul>

                                                <div className="getInvolveBehalf textAlignCenter marginTop20 missionText fontSize30 paddingBottom10"><span className="ShelteringSpan">On behalf of the families we serve, we thank you. </span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    ) : (
                        <>
                            <div className="container-fluid marginTop60">
                                <div className="hero--text textColorBlack"><h1 className="h10 hero--header">Get Involved</h1></div>
                                <div className='whoWeAre main'>
                                    <blockquote className="otro-blockquote textCenter">
                                        {quotationsList.GetInvolvedQuote.Quote}
                                        <span>{quotationsList.GetInvolvedQuote.Author}</span>
                                    </blockquote>
                                    <div id="box-search">
                                        <div className="thumbnail">
                                            <img src={HandRaiseImage} className='handRaiseImageOpacity HandeRaiseImage' alt=" HandRaiseImage " />
                                        </div>
                                        <div className="DivHandRaise DivHandRaiseLapTop">
                                            <div id="contain">
                                                <p className="fontResponsive padding5Right backgroundColorDefault testAlignLeft fontFamilyTimesNewRoman padding20">So many children and their families need our support. But we can’t help them on our own. So we’re asking you to get involved! Whether you choose to donate or just donate your time, your kindness means everything. If you choose to volunteer, your help allows us to devote more resources to the families and hospitals we serve. Whether you choose to provide transportation, lend a hand, raise awareness, or help in a way we haven’t thought of yet, your compassion leaves a tremendous impact on our families!</p>
                                            </div>

                                            <ul className="list-group">
                                                <li className="list-group-item justify-content-between backgroundColorDefault fontFamilyTimesNewRoman">
                                                    <FiberManualRecordIcon className="starRed" /><span className='fontResponsive'> For volunteer opportunities you can contact us at <a href="mailto:info@aashraycharities.org">info@aashraycharities.org</a> or call us at 732-354-1881</span>
                                                </li>
                                                <li className="list-group-item justify-content-between backgroundColorDefault fontFamilyTimesNewRoman">
                                                    <FiberManualRecordIcon className="starRed" /> <span className='fontResponsive'> If you can’t give your time don’t worry. You can donate to show your support. If you are an individual or corporation, you can raise funds by visiting our  <Link to="/donation"> donate page</Link></span>
                                                </li>
                                                <li className="list-group-item justify-content-between backgroundColorDefault fontFamilyTimesNewRoman">
                                                    <FiberManualRecordIcon className="starRed" /> <span className='fontResponsive'> If you are a hotel ready to offer your services or a hospital in need of support, you can email us at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a></span>
                                                </li>
                                            </ul>

                                            <div className="getInvolveBehalf textAlignCenter marginTop20 missionText fontSize30 paddingBottom10"><span className="ShelteringSpan">On behalf of the families we serve, we thank you. </span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
                <FooterComponent />
            </section>
        </div>
    )
}
export default GetInvolvedComponent;