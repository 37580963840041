import * as React from "react";
import hospital from "../../json/hospitalinfo.json"
import { IHotelsProps } from "../../helper/interfaces";
import { Tooltip } from '@mui/material';
import FooterComponent from "../footer/footer";
import AccommodationComponent from './accommodations';
import { useNavigate } from "react-router-dom";

const FindAccommodationsComponent = () => {
    const [selectHospital, setSelectHospital] = React.useState("");
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1200);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorDiv, setErrorDiv] = React.useState("None");
    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile])
   
    const onHandleFindButton = () => {
        if (selectHospital !== "") {
            navigate("/online-application");
        } else {
            setErrorDiv("Block")
            setErrorMessage(" Hospital is required.Please Select...")
        }
    }

    const handleChange = (event: any) => {
        setSelectHospital(event.target.value);
        setErrorDiv("None");
    };
    return (
        <div className="main-body">
            <AccommodationComponent />
            <div className="main" style={{ marginTop: "30px" }}>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12 alignTextCenter">
                                <div className="card-header defaultHeaderColor">
                                    <span className='founderStorySpan textCapitalize textDecorationUnderline fontWeightBold'> Find Accommodations </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body find-accomodationcardDiv">
                        <p className="textIndent30 missionTextAlignmentLeft missionText fontSize25">
                        At Aashray, we are always striving to expand our network of affiliate hospitals. Currently we are serving the families at The Bristol Myers Squibb Children's Hospital at Robert Wood Johnson Hospital in New Brunswick, New Jersey. Please select your treating hospital below to apply for accommodations.
                        </p>
                    </div>
                </div>
                <div className="card hospitalDataGrid">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12 alignTextCenter">
                                <div className="card-header defaultHeaderColor">
                                    <span className='founderStorySpan textCapitalize textDecorationUnderline fontWeightBold'> Hospital Information </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card hospitalDataGrid">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="card-header findAccommodationDiv">
                                    <span className='findAccommodationHospital aboutUsAccommodations'> Choose Hospital </span>
                                </div>
                            </div>

                            <div className="col-sm-6" style={{ marginTop: 10 }}>
                                <Tooltip title={selectHospital}>
                                    <select className="form-select" onChange={handleChange}><option>Please Select Any</option>
                                        {hospital.map(x => (
                                            <option key={x.PreHospitalName} value={x.PreHospitalName}>
                                                {x.PreHospitalName}
                                            </option>
                                        ))}
                                    </select>
                                </Tooltip>
                            </div>

                            <div className="col-sm-2" style={{ marginTop: 10 }}>
                                <button className="btn btn-sm btn-primary form-control btnFindButton" onClick={onHandleFindButton} style={{ borderRadius: 10 }} type="button">
                                    Find
                                </button>
                            </div>
                        </div>
                        <div className={`${errorDiv}Error row`} style={{ marginTop: 10 }}>
                            <span className="errorSpan">{errorMessage}</span>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComponent />
        </div>
    )
}
export default FindAccommodationsComponent;