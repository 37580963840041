import * as React from 'react';
import NavbarComponent from '../navbar/navbar';
import GuestImage from "../../Images/guest-image.jpg";
import DonateIcon from "../../Images/donate-icon.jpeg";
import hotels from "../../json/hotel-image.json";
import hospitals from "../../json/hospitalinfo.json";
import HomeHotelSlideComponent from './home-hotel-slide';
import FooterComponent from '../footer/footer';
import PaymentForm from '../donation/payment-form';
import { PaymentGateWay } from '../../helper/interfaces';
import PaymentGateWayJson from '../../json/payment-app-type.json';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AboutUs2Image from "../../Images/about-us-2.jpeg"
import newsIcon from "../../Images/news.jpeg";
import WhatWeDoImage from "../../Images/what-we-do-2.jpeg"

const HomeComponent = (props: any) => {
    const myRef = React.useRef<HTMLDivElement>(null);
    // const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024 && window.innerWidth > 880);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 577 && window.innerWidth < 1024);
    const [isLessMobile, setIsLessMobile] = React.useState(window.innerWidth <= 577);
    const [isBigScreen, setIsBigScreen] = React.useState(window.innerWidth > 1330);
    const PaymentGateWayUrl: PaymentGateWay = PaymentGateWayJson;
    const paymentUrl = `${PaymentGateWayUrl.BaseUrl}${PaymentGateWayUrl.PaymentAppName}`;

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const istablet = window.innerWidth > 577 && window.innerWidth < 1024;
            const islessmobile = window.innerWidth <= 577;
            const isbigScreen = window.innerWidth > 1330;
            if (istablet !== isTablet) {
                setIsTablet(istablet);
            } else if(isLessMobile !== islessmobile){
                setIsLessMobile(islessmobile)
            }else{
                setIsBigScreen(isbigScreen)
            }
        }, false);

        if (myRef && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: "center"
            });
        }
    }, [isTablet, isLessMobile,isBigScreen])
    return (
        <div className="main-body">
            <NavbarComponent />
            <>
                {isTablet || isLessMobile ? (
                    <div className="imageBody">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="heroTextMobile margin30top"><h1 className="heroHeader marginAuto balance-text textCenter fontFamilyTimesNewRoman mx-auto h20 lg-text-3xl">Help Us Keep Families Fighting Childhood Illness Together</h1>
                                    <p className="marginAuto fontFamilyTimesNewRoman mobile">Imagine learning that your child has been diagnosed with a serious illness. Devastated, you then discover that the best treatment for your child is tens, sometimes hundreds of miles away from home. What would you do? Would you leave your child alone in the hospital as you travel back and forth? Or would you empty your pockets just to rent a temporary hotel room near your child? Sadly, far too many families face these decisions far too often. But together, we can put a stop to it. Whether you give once or monthly, know that 100% of your donations goes towards helping patient families in need.</p></div>
                            </div>
                            <div className="col-sm-12">
                                <div ref={myRef} id="myRef donationView" className="col-sm-12">
                                    <div className="paymentForm">
                                        <PaymentForm Name="Home" paymentUrl={paymentUrl} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="imageBodyPc">
                        <div className="row">
                            <div className={`${isBigScreen ? 'col-sm-3':'col-sm-4'}`}>
                                <div ref={myRef} id="myRef donationView" className="col-sm-12">
                                    <div className="paymentForm margin80Left">
                                        <PaymentForm Name="Home" paymentUrl={paymentUrl} />
                                    </div>
                                </div>
                            </div>
                            <div className={`${isBigScreen ? 'col-sm-9':'col-sm-8'}`}>
                                <div className={`${isBigScreen ? "heroText marginTop9Per" : "heroText marginTop30Per"}`}><h1 className="heroHeader balance-text mx-auto h20 lg-text-3xl">Help Us Keep Families Fighting Childhood Illness Together</h1>
                                    <p className="hero--description mx-auto balance-text p30">Imagine learning that your child has been diagnosed with a serious illness. Devastated, you then discover that the best treatment for your child is tens, sometimes hundreds of miles away from home. What would you do? Would you leave your child alone in the hospital as you travel back and forth? Or would you empty your pockets just to rent a temporary hotel room near your child? Sadly, far too many families face these decisions far too often. But together, we can put a stop to it. Whether you give once or monthly, know that 100% of your donations goes towards helping patient families in need.</p></div>
                            </div>
                        </div>
                    </div>
                )}
            </>
            <div className="space"></div>
            <section className='section'>
                <div className="">
                    <span className="text-eyebrow-cw-secondary block mb-2">How We Work</span>
                    <p className="p30 max-w-sm mx-auto backgroundColorDefault mobilePadding" style={{ maxWidth: '592.535px' }}>
                        Aashray helps hospitals better utilize the existing infrastructure surrounding them. By identifying and then partnering with the local hotels neighboring hospitals, we fund or create hospital programs that offer much-needed hotel accommodations to families. By working with local hotels, Aashray negotiates reduced rates and covers the cost of each stay, maximizing the number of families who can benefit.
                    </p>
                </div>

                <div className="max-w-md mx-auto marginTopModifier">
                    <span className="text-eyebrow-cw-secondary block mb-2">Our Mission</span>
                    <p className="p30 max-w-sm mx-auto  backgroundColorDefault mobilePadding" style={{ maxWidth: '592.535px' }}>
                        More than three million children are hospitalized every year in the United States. While for some a stay in the hospital is a planned event, for most children their admission to the hospital is because of a sudden illness or injury. Unfortunately, planned or not, more than three-quarters of these families live in their child’s hospital room just so their children can get the care they need. They sacrifice their sleep, nutrition, work, and overall well-being. We’re on a mission to change that.
                    </p>
                </div>

                <>
                    {isTablet || isLessMobile ? (
                        <div className="row">
                            <div className='col-sm-12 marginTop50'>
                                <a className="textDecorativeNone" href="/" onClick={() => {
                                    window.open('https://form.jotform.com/233025355754455', '_blank', 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes');
                                }}>
                                    <div className="card">
                                        <div className='card-body'>
                                            <>
                                                <div className="boarder1px">
                                                    <div className='row'>
                                                        <div className="col-sm-6">
                                                            <div className="HospitalImage padding10">
                                                                <img src={GuestImage} alt="Guest Icon" className='responsive hotelImages' />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="card-body padding10">
                                                                <Typography className="defaultColor textCenter marginBottom15 textDecorativeNone" component="div" variant="h5">
                                                                    Share Your Voice
                                                                </Typography>
                                                                <p className="p30 fontSize16 fontText fontFamilyTimesNewRoman mx-auto backgroundColorDefault">Thank you for staying in one of our partner hotels! We know that filling out a survey is probably the last thing on your mind. However, we’d love to hear your feedback. It helps us get better. If possible,
                                                                    please <a className="textDecorativeNone" href="/" onClick={() => {
                                                                        window.open('https://form.jotform.com/233025355754455', '_blank', 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes');
                                                                    }}>click here</a>  to complete our evalution survey.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className='col-sm-12'>
                                <Link className='textDecorativeNone' to="/sponsor-a-family">
                                    <div className="card">
                                        <div className='card-body'>
                                            <>
                                                <div className="boarder1px">
                                                    <div className='row'>
                                                        <div className="col-sm-6">
                                                            <div className="HospitalImage padding10">
                                                                <img src={DonateIcon} className="responsive hotelImages" alt="Donate Icon" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="card-body padding10">
                                                                <Typography className=" textCenter marginBottom15 textDecorativeNone defaultColor" component="div" variant="h5">
                                                                    Sponsor a Family
                                                                </Typography>
                                                                <p className="p30 fontSize16 fontText justifyText fontFamilyTimesNewRoman mx-auto backgroundColorDefault">Change the life of a family! With a $1000 donation you can provide a week’s worth of hotel accommodations for a family! <Link className="text-cw-primary uppercase anchor underlined leading-normal mt-2 mb-1" to="/sponsor-a-family">Learn More</Link></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className="row row marginTop50 marginBottom50 marginLeft20 marginRight20">
                            <div className="col-sm-6 borderOfCard">
                                <a className="textDecorativeNone" href="/" onClick={() => {
                                    window.open('https://form.jotform.com/233025355754455', '_blank', 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes');
                                }}>
                                    <div className="card mb-3">
                                        <div className="row g-0">
                                            <div className="col-sm-5">
                                                <img src={GuestImage} className="img-fluid rounded-start founderImages" alt="Donate Icon" />
                                            </div>
                                            <div className="col-sm-7">
                                                <div className="card-body marginLeft10">
                                                    <Typography className="defaultColor marginBottom15 textDecorativeNone" component="div" variant="h5">
                                                        Share Your Voice
                                                    </Typography>
                                                    <p className="p30 fontSize16 fontFamilyTimesNewRoman mx-auto backgroundColorDefault">Thank you for staying in one of our partner hotels! We know that filling out a survey is probably the last thing on your mind. However, we’d love to hear your feedback. It helps us get better. If possible,
                                                        please <a className="textDecorativeNone" href="/" onClick={() => {
                                                            window.open('https://form.jotform.com/233025355754455', '_blank', 'toolbar=yes, location=yes, status=yes, menubar=yes, scrollbars=yes');
                                                        }}>click here</a> to complete our evalution survey.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-6 borderOfCard">
                                <Link className='textDecorativeNone' to="/sponsor-a-family">
                                    <div className="card mb-3">
                                        <div className="row g-0">
                                            <div className="col-sm-5">
                                                <img src={DonateIcon} className="img-fluid rounded-start" alt="Donate Icon" />
                                            </div>
                                            <div className="col-sm-7">
                                                <div className="card-body marginLeft10">
                                                    <Typography className="marginBottom15 textDecorativeNone defaultColor" component="div" variant="h5">
                                                        Sponsor a Family
                                                    </Typography>
                                                    <p className="p30 fontSize16 fontFamilyTimesNewRoman mx-auto backgroundColorDefault">Change the life of a family! With a $1000 donation you can provide a week’s worth of hotel accommodations for a family!</p>
                                                    <p className="p30 fontSize16 textAlignCenter fontFamilyTimesNewRoman mx-auto backgroundColorDefault marginTop15"><Link className="text-cw-primary uppercase anchor underlined leading-normal mt-2 mb-1" to="/sponsor-a-family">Learn More</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    )}
                </>
                <>
                {
                    isTablet || isLessMobile ? (
                            <>
                                

                                <div className='row marginTop50 marginBottom80'>
                                <div className='col-sm-12'>
                                    <div className="card">
                                        <div className='card-header colorWhite'>
                                            <span className='text-eyebrow-cw-secondary block'>Hospitals We Serve </span>
                                        </div>
                                        <div className='card-body'>
                                            <>
                                                {
                                                    hospitals.map((x, index) => {
                                                        return <>
                                                            <div key={index} className="slideshow">
                                                                <div className="row">
                                                                    <span className='missionText fontSize26 textDecorationUnderline'>{x.PreHospitalName}</span>
                                                                    <span className='missionText fontSize24'>{x.PostHospitalName}</span>
                                                                </div>
                                                                <div className="HospitalImage">
                                                                    <img src={x.ImageUrl} alt={x.PostHospitalName} className='responsive hotelImages' />
                                                                </div>
                                                            </div>
                                                        </>
                                                    })
                                                }
                                            </>
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div className='row marginTop50 marginBottom80'>
                                    <div className='col-sm-12'>
                                        <div className="card">
                                            <div className='card-header colorWhite'>
                                                <span className='text-eyebrow-cw-secondary block'>Our Hotel Partners </span>
                                            </div>
                                            <div className='card-body'>
                                                <div className="row">
                                                    {
                                                        hotels.Hotels.map((x, index) => {
                                                            return <div key={index} className="col-sm-12">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <HomeHotelSlideComponent Name={x} images={x.Images} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    ):(
                                <>
                                <div className='row marginTop50 marginBottom80'>
                                        <div className='col-sm-12'>
                                            <div className="card">
                                                <div className='card-header marginBottom30 colorWhite'>
                                                    <span className='text-eyebrow-cw-secondary block'>Hospitals We Serve </span>
                                                </div>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        {
                                                            hospitals.map((x, index) => {
                                                                return (
                                                                    <div className='col-sm-6'>
                                                                        <div key={index} className="slideshow">
                                                                        <div className="row">
                                                                            <span className='missionText fontSize26 textDecorationUnderline'>{x.PreHospitalName}</span>
                                                                            <span className='missionText fontSize24'>{x.PostHospitalName}</span>
                                                                        </div>
                                                                        <div className="HospitalImageDesktop">
                                                                            <img src={x.ImageUrl} alt={x.PostHospitalName} className='responsive hotelImages' />
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row marginTop50 marginBottom80'>
                                        <div className='col-sm-12'>
                                            <div className="card">
                                                <div className='card-header colorWhite'>
                                                    <span className='text-eyebrow-cw-secondary block'>Our Hotel Partners </span>
                                                </div>
                                                <div className='card-body'>
                                                    <div className="row">
                                                        {
                                                            hotels.Hotels.map((x, index) => {
                                                                return (
                                                                    <div key={index} className="col-sm-12">
                                                                        <HomeHotelSlideComponent Name={x} images={x.Images} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                }
                </>

                <div className="marginBottom50 marginTop50">
                    {/* <span className="text-eyebrow-cw-secondary block mb-2">Who We are</span> */}
                </div>

                {isLessMobile || isTablet ? (
                    <div id="wrapper marginBottom50 margin50top">
                        <div className="party">
                            <h3 className='header'>About Us</h3>
                            <div className="party-image-wrapper">
                                <img alt="About Us" className='partyImages' src={AboutUs2Image} />
                            </div>

                            <p className="headerPara">Aashray is a Hindi word which, as a noun, can mean refuge, shelter, harbor, or haven. As a verb, it can mean to take refuge, to harbor...</p>
                            <p className="marginTop20 p30 fontSize16 textAlignCenter fontFamilyTimesNewRoman mx-auto backgroundColorDefault"><Link className="text-cw-primary uppercase anchor underlined leading-normal mt-2 mb-1" to="/who-we-are">Read More</Link></p>
                        </div>

                        <div className="party">
                            <h3 className='header'>News</h3>
                            <div className="party-image-wrapper">
                                <img alt="Founder Story" className='partyImages' src={newsIcon} />
                            </div>

                            <p className="headerPara"><b>HOT OF THE PRESS!!!</b> We are thrilled to announce the addition of The Children's Hospital at Saint Peter's University Hospital to the...</p>
                            <p className="marginTop20 p30 fontSize16 textAlignCenter fontFamilyTimesNewRoman mx-auto backgroundColorDefault"><Link className="text-cw-primary uppercase anchor underlined leading-normal mt-2 mb-1" to="/news">Read More</Link></p>
                        </div>

                        <div className="party">
                            <h3 className='header'>What We Do</h3>
                            <div className="party-image-wrapper">
                                <img alt="What We Do" className='partyImages' src={WhatWeDoImage} />
                            </div>

                            <p className="headerPara">At Aashray we believe that no family should have to choose between their child's health or affordability. We strive to alleviate these...</p>
                            <p className="marginTop20 p30 fontSize16 textAlignCenter fontFamilyTimesNewRoman mx-auto backgroundColorDefault"><Link className="text-cw-primary uppercase anchor underlined leading-normal mt-2 mb-1" to="/what-we-do">Read More</Link></p>
                        </div>
                    </div>
                ) : (
                    <div id="wrapper marginBottom50 margin50top">
                        <div className="party">
                            <h3 className='header'>About Us</h3>
                            <div className="party-image-wrapper">
                                <img alt="About Us" className='partyImages' src={AboutUs2Image} />
                            </div>

                            <p className="headerPara">Aashray is a Hindi word which, as a noun, can mean refuge, shelter, harbor, or haven. As a verb, it can mean to take refuge, to harbor...</p>
                            <p className="p30 fontSize16 textAlignCenter fontFamilyTimesNewRoman mx-auto backgroundColorDefault"><Link className="text-cw-primary uppercase anchor underlined leading-normal mt-2 mb-1" to="/who-we-are">Read More</Link></p>
                        </div>

                        <div className="party">
                            <h3 className='header'>News</h3>
                            <div className="party-image-wrapper">
                                <img alt="Founder Story" className='partyImages' src={newsIcon} />
                            </div>

                            <p className="headerPara"><b>HOT OF THE PRESS!!!</b> We are thrilled to announce the addition of The Children's Hospital at Saint Peter's University Hospital to the...</p>
                            <p className="p30 fontSize16 textAlignCenter fontFamilyTimesNewRoman mx-auto backgroundColorDefault"><Link className="text-cw-primary uppercase anchor underlined leading-normal mt-2 mb-1" to="/news">Read More</Link></p>
                        </div>

                        <div className="party">
                            <h3 className='header'>What We Do</h3>
                            <div className="party-image-wrapper">
                                <img alt="What We Do" className='partyImages' src={WhatWeDoImage} />
                            </div>

                            <p className="headerPara">At Aashray we believe that no family should have to choose between their child's health or affordability. We strive to alleviate these...</p>
                            <p className="p30 fontSize16 textAlignCenter fontFamilyTimesNewRoman mx-auto backgroundColorDefault"><Link className="text-cw-primary uppercase anchor underlined leading-normal mt-2 mb-1" to="/what-we-do">Read More</Link></p>
                        </div>
                    </div>
                )}
            </section>
            <FooterComponent />
        </div>
    )
}
export default HomeComponent