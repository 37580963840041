import * as React from "react";
import JotForm from "./jot-form";
import AccommodationComponent from './accommodations';
import FooterComponent from "../footer/footer";

const OnlineApplicationComponent = () => {
    return (
        <div className="main-body">
            <section className='section'>
                <AccommodationComponent />
                <div className="jotForm">
                    <JotForm />
                </div>
            </section>
            <FooterComponent />
        </div>
    )
}
export default OnlineApplicationComponent;